import React from 'react';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
  return (
      <nav className="navbar navbar-expand-lg navbar-dark d-flex justify-content-between">
        <div className="container-fluid">
          <NavLink className="navbar-brand text-bold" to="/" activeclassname="active">Cyber Gurukulam</NavLink>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav ">
              <NavLink className="nav-link" to="/" activeclassname="active">Home </NavLink>
              <NavLink className="nav-link" to="/about" activeclassname="active">About Us </NavLink>
              <NavLink className="nav-link" to="/contact" activeclassname="active">Contact Us</NavLink>
            </div>
          </div>
        </div>
      </nav>
  );
}

export default Navbar;
